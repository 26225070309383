import React, { useState } from "react";
import CustomDatePicker from "../../re-usable-components/datepicker/datepicker";
import "../user/user.css";

const genderDetails = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
  { id: "other", name: "Others" },
];

export const UserForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    gender: "",
    dateOfBirth: null,
  });
  const [steps, setSteps] = useState([
    { name: 'Step 1', status: 'current', href: '#step1' },
    { name: 'Step 2', status: 'incomplete', href: '#step2' },
]);

  const handleDateSelect = (date) => {
    setFormData({ ...formData, dateOfBirth: date });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };
  const handleStepClick = (step) => {
    const updateSteps = steps.map(s => {
      if (step === s) {
        return {...s, status: 'current'}
      } else if(s.status === 'current') {
        return{...s, status: 'complete'}
      }
      return s;
    });
    setSteps(updateSteps);
    console.log('step', step)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="feature">
        <h2 className="text-lg font-medium text-gray-900"> User Information</h2>
        <div className="mt-4 mx-4">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-black"
            >
              First Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                autoComplete="given-name"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-black"
            >
              Last Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                autoComplete="given-name"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="email-address"
              className="block text-sm font-medium text-black"
            >
              Email Address
            </label>
            <div className="mt-1">
              <input
                type="email"
                id="email-address"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleInputChange}
                autoComplete="email"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-2">
            <label className="block text-sm font-medium text-black">
              Date Of Birth
            </label>
            <div style={{ textAlign: "left" }}>
              <CustomDatePicker onDateSelect={handleDateSelect} />
            </div>
          </div>
          <div className="mt-2">
            <label className="block text-sm font-medium text-black">
              Gender
            </label>
            <div className="flex">
              {genderDetails.map((prop) => (
                <div key={prop.id} className="mx-5">
                  <input
                    id={prop.id}
                    name="gender"
                    type="radio"
                    value={prop.id}
                    checked={formData.gender === prop.id}
                    onChange={handleInputChange}
                    className="h-4 w-4 border-gray-300 text-indigo-300 focus:ring-indigo-600"
                    style={{ marginRight: "5px" }}
                  />
                  <label
                    htmlFor={prop.id}
                    className="ml-3 text-sm font-medium leading-6 text-gray-900"
                  >
                    {prop.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {/* Navigation bar starts from here */}
          <div className="mt-5">
            <nav
              className="flex items-center justify-center"
              aria-label="Progress"
            >
              <p className="text-sm font-medium">
                Step {steps.findIndex((step) => step.status === "current") + 1}{" "}
                of {steps.length}
              </p>
              <ul className="ml-8 flex items-center space-x-5">
                {steps.map((step) => (
                  <li key={step.name}>
                    {step.status === "complete" ? (
                      <a
                        href={step.href}
                        className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900"
                        onClick={() => handleStepClick(step)}
                      >
                        <span className="sr-only">{step.name}</span>
                      </a>
                    ) : step.status === "current" ? (
                      <a
                        href={step.href}
                        className="relative flex items-center justify-center"
                        aria-current="step"
                      >
                        <span
                          className="absolute flex h-5 w-5 p-px"
                          aria-hidden="true"
                        >
                          <span className="h-full w-full rounded-full bg-indigo-200" />
                        </span>
                        <span
                          className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                          aria-hidden="true"
                        />
                        <span className="sr-only">{step.name}</span>
                      </a>
                    ) : (
                      <a
                        href={step.href}
                        className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
                        onClick={() => handleStepClick(step)}
                      >
                        <span className="sr-only">{step.name}</span>
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </form>
  );
};
export default UserForm;
