import { useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import chatbotSystemDesign from "../../icons/chatbot-system-design.jpg";
import intentClarity from "../../icons/intent-clarity.jpg";
import promptFlow from "../../icons/prompt-flow.jpg";
import Meta from "../../re-usable-components/meta/meta";

const stats = [
  { label: "Founded", value: "2021" },
  { label: "Employees", value: "37" },
  { label: "Countries", value: "12" },
  { label: "Raised", value: "$25M" },
];

const ProjectReport = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta title="Project Report | A Detailed Analysis - ShopAssist" />
      <section className="min-h-screen">
        <div className="bg-orange-50 py-14 sm:py-24">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-orange-100">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2 pt-16">
              <div className="lg:pr-4">
                <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                  <img
                    className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
                    src="https://images.unsplash.com/photo-1630569267625-157f8f9d1a7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2669&q=80"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gray-900 mix-blend-multiply" />
                  <div
                    className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
                    aria-hidden="true"
                  >
                    <div
                      className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-40"
                      style={{
                        clipPath:
                          "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, '72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                      }}
                    />
                  </div>
                  <figure className="relative isolate">
                    <span className="text-4xl font-semibold text-white">
                      Objectives
                    </span>
                    <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                      <p>
                        “The main objective of the ShopAssist AI project was to
                        develop an intelligent chatbot that can provide
                        personalized laptop recommendations to users based on
                        their specific requirements and preferences. The chatbot
                        aimed to simulate a knowledgeable laptop gadget expert,
                        engaging in natural conversations with users to
                        understand their needs and guide them towards the most
                        suitable laptop options.”
                      </p>
                    </blockquote>
                  </figure>
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    Company values
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Key goals of the project included
                  </h1>
                  <div className="max-w-xl">
                    <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        1.
                        <span>
                          Developing a conversational interface that allows
                          users to express their laptop requirements in a
                          natural and intuitive manner.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        2.
                        <span>
                          Leveraging large language models (LLMs) and rule-based
                          functions to extract relevant information from user
                          inputs and match them with a comprehensive laptop
                          dataset.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        3.
                        <span>
                          Implementing a multi-stage system design that
                          incorporates intent clarity, intent confirmation,
                          product mapping, information extraction, and product
                          recommendation layers.
                        </span>
                      </li>
                    </ul>
                    <p className="mt-6">
                      Faucibus commodo massa rhoncus, volutpat. Dignissim sed
                      eget risus enim. Mattis mauris semper sed amet vitae sed
                      turpis id. Id dolor praesent donec est. Odio penatibus
                      risus viverra tellus varius sit neque erat velit. Faucibus
                      commodo massa rhoncus, volutpat. Dignissim sed eget risus
                      enim. Mattis mauris semper sed amet vitae sed turpis id.
                    </p>
                  </div>
                </div>
                <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
                  {stats.map((stat, statIdx) => (
                    <div key={statIdx}>
                      <dt className="text-sm font-semibold leading-6 text-gray-600">
                        {stat.label}
                      </dt>
                      <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">
                        {stat.value}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="py-16">
              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div>
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    Conversational Laptop Recommendation System
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Key goals of the project included:
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Developing a conversational interface that allows
                          users to express their laptop requirements in a
                          natural and intuitive manner.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Leveraging large language models (LLMs) and rule-based
                          functions to extract relevant information from user
                          inputs and match them with a comprehensive laptop
                          dataset.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Implementing a multi-stage system design that
                          incorporates intent clarity, intent confirmation,
                          product mapping, information extraction, and product
                          recommendation layers.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Ensuring accurate and reliable information delivery by
                          validating user intents, moderating conversations, and
                          providing consistent recommendations.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Enhancing user satisfaction by personalizing laptop
                          recommendations based on individual preferences and
                          budget constraints.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    ShopAssist AI System Architecture
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Design and Implementation
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="mt-6">
                      The ShopAssist AI project followed a modular and layered
                      design approach to ensure scalability, maintainability,
                      and extensibility. The system architecture consisted of
                      three main stages: Intent Clarity and Confirmation,
                      Product Mapping and Information Extraction, and Product
                      Recommendation.
                    </p>
                    <figure>
                      <img
                        className="aspect-video rounded-xl bg-gray-50 object-cover"
                        src={chatbotSystemDesign}
                        alt=""
                      />
                    </figure>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    How a Chatbot Assists in Laptop Purchase: Understanding User
                    Requirements
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Stage 1: Intent Clarity and Confirmation
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          The chatbot initiated a conversation with the user,
                          prompting them to share their laptop requirements.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Natural language processing techniques were employed
                          to understand user inputs and extract relevant
                          information.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          The chatbot asked clarifying questions to gather more
                          specific details about the user's preferences, such as
                          GPU intensity, display quality, portability,
                          multitasking needs, processing speed, and budget.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Intent confirmation was performed to validate the
                          chatbot's understanding of the user's requirements and
                          ensure accurate mapping to predefined laptop feature
                          categories.
                        </span>
                      </li>
                    </ul>
                    <figure className="mt-4">
                      <img
                        className="aspect-video rounded-xl bg-gray-50 object-cover"
                        src={intentClarity}
                        alt=""
                      />
                    </figure>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    Laptop Recommendation System: Feature Extraction and
                    Matching
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Stage 2: Product Mapping and Information Extraction
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          The laptop dataset was preprocessed and enriched with
                          key features and specifications.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Rule-based functions were developed to extract laptop
                          features from product descriptions and map them to
                          corresponding categories (e.g., GPU intensity, display
                          quality, portability).
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Each laptop in the dataset was assigned a score based
                          on how well it matched the user's requirements.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          The top three laptops with the highest scores were
                          selected as potential recommendations.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    Presenting Personalized Laptop Recommendations and Enabling
                    User Refinement
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Stage 3: Product Recommendation
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          The chatbot presented the top three laptop
                          recommendations to the user in a clear and concise
                          format.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Users could ask follow-up questions or request more
                          information about specific laptops.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          The chatbot provided detailed responses, leveraging
                          the comprehensive laptop dataset and its knowledge
                          base.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Users could refine their requirements or explore
                          alternative options based on the provided
                          recommendations.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    Technical Infrastructure for the Laptop Recommendation
                    Chatbot
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    The implementation of ShopAssist AI involved the following
                    key components and technologies
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Python programming language for backend development
                          and data processing.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          OpenAI's GPT-3.5 model and the Chat Completions API
                          for natural language understanding and generation.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Pandas library for efficient data manipulation and
                          analysis.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          JSON format for structured data exchange between
                          different system components.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Moderation API to ensure safe and appropriate
                          conversations.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Few-shot prompting and chain-of-thought reasoning
                          techniques to guide the chatbot's responses.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Dictionary matching algorithms for laptop feature
                          extraction and comparison.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    Overcoming Challenges in Developing ShopAssist AI
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Challenges
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="mt-6">
                      During the development of ShopAssist AI, several
                      challenges were encountered and addressed:
                    </p>
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3 flex-col">
                        <span>
                          <strong>1.</strong> Balancing Personalization and
                          Generalization:
                        </span>
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            Striking the right balance between personalized
                            recommendations and generalized responses was
                            challenging.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            Careful design of prompts, rules was necessary to
                            ensure flexibility and adaptability.
                          </span>
                        </li>
                      </li>
                      <li className="flex gap-x-3 flex-col">
                        <span>
                          <strong>2.</strong> Conversational Flow Management:
                        </span>
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            Handling user digressions, irrelevant inputs, and
                            maintaining context across multiple turns posed
                            challenges.
                          </span>
                        </li>
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            Implementing a structured conversation flow with
                            clear transitions, confirmations, and error handling
                            mechanisms helped improve the user experience.
                          </span>
                        </li>
                      </li>
                      <li className="flex gap-x-3 flex-col">
                        <span>
                          <strong>3.</strong> Dataset Quality and Consistency:
                        </span>
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            Ensuring the quality and consistency of the laptop
                            dataset was crucial for providing accurate
                            recommendations.
                          </span>
                        </li>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    Key Takeaways for Building a Successful Chatbot
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Lessons learned
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Building a successful chatbot is an iterative process
                          that requires continuous improvement and refinement.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          The quality and completeness of the laptop dataset
                          directly impact the accuracy and effectiveness of the
                          recommendations.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckCircleIcon
                          className="mt-1 h-5 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        <span>
                          Finding the right balance between rules and AI ensures
                          a seamless and intelligent conversation experience.
                        </span>
                      </li>
                    </ul>
                    <p className="mt-6">
                      A typical prompt flow can look like in the diagram below
                    </p>
                    <figure className="mt-4">
                      <img
                        className="aspect-video rounded-xl bg-gray-50 object-cover"
                        src={promptFlow}
                        alt=""
                      />
                    </figure>
                  </div>
                </div>

                <div className="mt-9">
                  <p className="text-base font-semibold leading-7 text-indigo-600">
                    ShopAssist AI: A Conversational AI Solution for Personalized
                    Laptop Recommendations
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Conclusion
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="mt-6">
                      The ShopAssist AI project successfully developed an
                      intelligent chatbot that provides personalized laptop
                      recommendations based on user requirements. By leveraging
                      large language models, rule-based functions, and a
                      multi-stage system design, the chatbot effectively engages
                      users in natural conversations, understands their needs,
                      and guides them towards the most suitable laptop options.
                    </p>
                    <p className="mt-6">
                      Throughout the development process, challenges related to
                      natural language understanding, dataset quality,
                      personalization, conversational flow management were
                      encountered and addressed. The project provided valuable
                      lessons on the importance of user-centric design, the
                      power of large language models, data quality, balancing
                      rule-based and AI-driven approaches, and continuous
                      improvement.
                    </p>
                    <p className="mt-6">
                      ShopAssist AI demonstrates the potential of conversational
                      AI in enhancing the online shopping experience for users
                      seeking laptop recommendations. With further enhancements
                      and iterations, the chatbot can be extended to cover a
                      wider range of products and provide even more personalized
                      and contextually relevant recommendations.
                    </p>
                    <p className="mt-6">
                      Future work on ShopAssist AI could involve integrating
                      user feedback mechanisms, incorporating sentiment analysis
                      to gauge user satisfaction, and exploring advanced
                      techniques like reinforcement learning to optimize
                      recommendation strategies. Additionally, expanding the
                      chatbot's knowledge base to include more laptop models,
                      brands, and specifications would enhance its versatility
                      and usefulness.
                    </p>
                    <p className="mt-6">
                      Overall, ShopAssist AI serves as a promising example of
                      how AI-powered chatbots can revolutionize the way users
                      interact with online shopping platforms, providing them
                      with personalized guidance and recommendations to make
                      informed purchase decisions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectReport;
