import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import SvgIcon from "../../../icons/SvgIcon";
import IconContainer from "../../../re-usable-components/icon-container/IconContainer";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const handelViewPassword = () => {
    switch (passwordType) {
      case "password":
        setPasswordType("text");
        break;
      case "text":
        setPasswordType("password");
        break;
      default:
        break;
    }
  };

  const handelViewConfirmPassword = () => {
    switch (confirmPasswordType) {
      case "password":
        setConfirmPasswordType("text");
        break;
      case "text":
        setConfirmPasswordType("password");
        break;
      default:
        break;
    }
  };

  const passViewIcon = (passwordType) => {
    switch (passwordType) {
      case "password":
        return <SvgIcon iconName="duo-eye" sizeName="lg" />;
      case "text":
        return <SvgIcon iconName="duo-eye-slash" sizeName="lg" />;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email required"),
      username: Yup.string()
        .required("User name required"),
      password: Yup.string()
        .min(4, "Must be 4 characters or more")
        .required("Password required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password required"),
    }),
    onSubmit: (values) => {
      console.log(values);

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const apiEndpoint = `${apiBaseUrl}/api/auth/register/`;

      const requestData = {
        username: values.username,
        password: values.password,
        email: values.email,
      };

      fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          console.log("Successfully Sing Up.");
          navigate("/home");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });

  return (
    <div className="my-4">
      <div className="text-black font-semibold">Sign Up</div>
      <div className="px-4 py-3 m-3 rounded-lg backdrop-blur-md">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-2">
            <input
              className="px-3 w-full leading-8 rounded text-base text-gray-800 bg-white border-gray-300 border outline-none focus:border-blue-500 focus:ring-1"
              name="email"
              type="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <div className="text-red-600 text-xs font-semibold text-left px-3 h-4">
              {formik.touched.email && formik.errors.email && (
                <>{formik.errors.email}</>
              )}
            </div>
          </div>

          <div className="mb-2">
            <input
              className="px-3 w-full leading-8 rounded text-base text-gray-800 bg-white border-gray-300 border outline-none focus:border-blue-500 focus:ring-1"
              name="username"
              type="text"
              placeholder="User Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            <div className="text-red-600 text-xs font-semibold text-left px-3 h-4">
              {formik.touched.username && formik.errors.username && (
                <>{formik.errors.username}</>
              )}
            </div>
          </div>

          <div className="mb-2">
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                className="px-3 w-full leading-8 rounded text-base text-gray-800 bg-white border-gray-300 border outline-none focus:border-blue-500 focus:ring-1"
                name="password"
                type={passwordType}
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                <IconContainer onClick={handelViewPassword}>
                  {passViewIcon(passwordType)}
                </IconContainer>
              </div>
            </div>
            <div className="text-red-600 text-xs font-semibold text-left px-3 h-4">
              {formik.touched.password && formik.errors.password && (
                <>{formik.errors.password}</>
              )}
            </div>
          </div>

          <div className="mb-2">
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                className="px-3 w-full leading-8 rounded text-base text-gray-800 bg-white border-gray-300 border outline-none focus:border-blue-500 focus:ring-1"
                name="confirmPassword"
                type={confirmPasswordType}
                placeholder="Confirm Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                <IconContainer onClick={handelViewConfirmPassword}>
                  {passViewIcon(confirmPasswordType)}
                </IconContainer>
              </div>
            </div>

            <div className="text-red-600 text-xs font-semibold text-left px-3 h-4">
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <>{formik.errors.confirmPassword}</>
                )}
            </div>
          </div>

          <button
            className="py-[6px] px-4 w-full text-base text-white font-semibold bg-[#4d4d4d] flex justify-center rounded border-0 hover:bg-[#8b8b8b] focus:outline-none"
            type="submit"
          >
            Sign Up
          </button>
        </form>
        <div className="px-3 mt-3 text-sm">
          <span>Already have an account?</span>
          <Link className="ml-4 text-[#004cff]" to="/auth/signin">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
