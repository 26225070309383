import { ReactComponent as DuoXmark } from "./svg/duotone/xmark.svg";
import { ReactComponent as DuoEye } from "./svg/duotone/eye.svg";
import { ReactComponent as DuoEyeSlash } from "./svg/duotone/eye-slash.svg";
import { ReactComponent as DuoBars } from "./svg/duotone/bars.svg";
import { ReactComponent as DuoMagnifyingGlass } from "./svg/duotone/magnifying-glass.svg";
import { ReactComponent as DuoUser } from "./svg/duotone/user.svg";
import { ReactComponent as DuoBagShopping } from "./svg/duotone/bag-shopping.svg";
import { ReactComponent as DuoAngleDown } from "./svg/duotone/angle-down.svg";
import { ReactComponent as DuoSpinnerThird } from "./svg/duotone/spinner-third.svg";

export const icons = {
  "duo-xmark": DuoXmark,
  "duo-eye": DuoEye,
  "duo-eye-slash": DuoEyeSlash,
  "duo-bars": DuoBars,
  "duo-magnifying-glass": DuoMagnifyingGlass,
  "duo-user": DuoUser,
  "duo-bag-shopping": DuoBagShopping,
  "duo-angle-down": DuoAngleDown,
  "duo-spinner-third": DuoSpinnerThird
};

export const size = {
  "2xs": 10,
  "xs": 12,
  "sm": 14,
  "lg": 20,
  "xl": 24,
  "2xl": 32,
  "1x": 16,
  "2x": 32,
  "3x": 48,
  "4x": 64,
  "5x": 80,
  "6x": 96,
  "7x": 112,
  "8x": 128,
  "9x": 144,
  "10x": 160,
};
