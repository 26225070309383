import { useEffect } from "react";

import Meta from "../../re-usable-components/meta/meta";
import localSwagger from "../../icons/local-swagger.png";
import homeUi from "../../icons/home-ui.png";
import chatUi from "../../icons/chat-ui.png";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const ProjectCodebase = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta title="Project Report | A Detailed Analysis - ShopAssist" />
      <section className="min-h-screen">
        <div className="bg-orange-50 py-14 sm:py-24">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-orange-100">
            <div className="py-16">
              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    How to run Backend locally (Django)
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Install Anaconda
                    </p>
                    <p className="mt-1">
                      Go to -
                      https://docs.anaconda.com/free/anaconda/install/windows/
                    </p>
                    <p className="mt-1">
                      Download anaconda and install in your system
                    </p>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Create conda environment
                    </p>
                    <code className="mt-1">
                      conda create --name genai-env python=3.9
                    </code>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Switch environment
                    </p>
                    <code className="mt-1">conda activate genai-env</code>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Install required package
                    </p>
                    <code className="mt-1">
                      conda env update --file environment.yml --prune
                    </code>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Run your Django project
                    </p>
                    <code className="mt-1">python manage.py runserver</code>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Open browser
                    </p>
                    <div className="flex items-center mt-1">
                      <p className="">Local URL -&nbsp;</p>
                      <a
                        href="http://127.0.0.1:8000/swagger/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://127.0.0.1:8000/swagger/
                      </a>
                    </div>
                    <div className="flex items-center mt-1">
                      <p className="">Live URL -&nbsp;</p>
                      <a
                        href="https://evalteam.pythonanywhere.com/swagger/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://evalteam.pythonanywhere.com/swagger/
                      </a>
                    </div>
                    <div className="mt-1">
                      <p className="">Image -&nbsp;</p>
                      <figure className="mt-2">
                        <img
                          className="aspect-video rounded-xl bg-gray-50 object-cover"
                          src={localSwagger}
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div className="mt-12">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    How to run Front End locally (React)
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Switch to project directory
                    </p>
                    <code className="mt-1">cd retail-buddy</code>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Install require package
                    </p>
                    <code className="mt-1">npm install</code>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Run project
                    </p>
                    <code className="mt-1">npm start</code>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Open browser
                    </p>
                    <div className="flex items-center mt-1">
                      <p className="">Local URL -&nbsp;</p>
                      <a
                        href="http://localhost:3000/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://localhost:3000/
                      </a>
                    </div>
                    <div className="flex items-center mt-1">
                      <p className="">Live URL -&nbsp;</p>
                      <a
                        href="http://shop.niisar.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://shop.niisar.com
                      </a>
                    </div>
                    <div className="mt-1">
                      <p className="">Image -&nbsp;</p>
                      <figure className="mt-2">
                        <img
                          className="aspect-video rounded-xl bg-gray-50 object-cover"
                          src={homeUi}
                          alt=""
                        />
                      </figure>
                    </div>
                    <div className="mt-6">
                      <p>When you click on Chat Link you see the Chat UI</p>
                      <figure className="mt-2">
                        <img
                          className="aspect-video rounded-xl bg-gray-50 object-cover"
                          src={chatUi}
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div className="mt-12">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Main Code Files
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Backend Code file
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            retailbuddy_app\api\views\shop_assist_views.py
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Frontend Code file
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            retail-buddy\src\pages\sidesheet-chat\sidesheet-chat.js
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div className="mt-12">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Backend Code Explanation
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      OverView
                    </p>
                    <p className="mt-2">
                      The provided code represents a laptop recommendation
                      system implemented as a Django REST API view (
                      <strong>ShopAssistView</strong>). The system uses OpenAI's
                      GPT-3.5-turbo model to engage in a conversational
                      interface with the user, understand their laptop
                      requirements, and provide personalized laptop
                      recommendations based on their preferences.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div className="mt-12">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Flow of the System
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Initialization
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The <strong>initialize_conversation()</strong>{" "}
                            function is called to set up the initial
                            conversation with the user. It defines the system
                            message that guides the AI's behavior and sets the
                            conversation context.
                          </span>
                        </li>

                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The conversation is initialized with the system
                            message.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      User Interaction
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The user sends a POST request to the{" "}
                            <strong>/chat</strong> endpoint with their input
                            text.
                          </span>
                        </li>

                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The <strong>post()</strong> method in the
                            <strong>ShopAssistView</strong> class receives the
                            user input and invokes the <strong>invite()</strong>{" "}
                            method to process the input.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Input Processing
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3 flex-col">
                          <span>
                            <strong>*</strong> The <strong>invite()</strong>{" "}
                            method performs the following steps:
                          </span>
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              It checks the user input for any moderation issues
                              using the <strong>moderation_check()</strong>{" "}
                              method. If any issues are found, an error response
                              is returned.
                            </span>
                          </li>
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              If no laptop recommendations have been made yet (
                              <strong>top_3_laptop</strong> is None), the user
                              input is appended to the conversation.
                            </span>
                          </li>
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The AI model generates a response based on the
                              conversation history using the{" "}
                              <strong>get_chat_completion()</strong> function.
                            </span>
                          </li>
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The response is checked for moderation issues.
                            </span>
                          </li>
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The <strong>intent_confirmation_layer()</strong>{" "}
                              method is called to confirm if the AI has gathered
                              sufficient information to make laptop
                              recommendations.
                            </span>
                          </li>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Recommendation Generation
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            If the intent confirmation layer indicates that
                            enough information has been collected, the{" "}
                            <strong>dictionary_present()</strong> method is
                            called to extract the user's laptop requirements
                            from the AI's response.
                          </span>
                        </li>

                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The <strong>compare_laptops_with_user()</strong>{" "}
                            method is invoked to compare the user's requirements
                            with the available laptops in the database and
                            generate a list of top 3 recommended laptops.
                          </span>
                        </li>

                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The <strong>recommendation_validation()</strong>{" "}
                            method validates the recommended laptops and filters
                            out any laptops with a score of 0.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Recommendation Presentation
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The <strong>initialize_conv_reco()</strong> method
                            is called to initialize a new conversation
                            specifically for presenting the recommended laptops
                            to the user.
                          </span>
                        </li>

                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The <strong>get_func_completion()</strong> method is
                            used to generate a response that includes the
                            details of the recommended laptops.
                          </span>
                        </li>

                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The generated recommendation is appended to the
                            conversation and sent back to the user.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="text-base font-semibold leading-7 text-indigo-600">
                      Subsequent User Interactions
                    </p>
                    <div className="mt-3">
                      <ul className="space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            If the user sends further inputs after receiving the
                            initial recommendations, the{" "}
                            <strong>invite()</strong> method processes the input
                            and generates appropriate responses using the{" "}
                            <strong>get_func_completion()</strong> method.
                          </span>
                        </li>

                        <li className="flex gap-x-3">
                          <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          <span>
                            The conversation continues until the user ends the
                            session or the conversation reaches a natural
                            conclusion.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div className="mt-12">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Frontend Code Explanation
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="mt-2">
                      This code represents a React component called{" "}
                      <strong>SideSheetChat</strong> that implements a chat
                      interface for a laptop recommendation system. The
                      component uses the{" "}
                      <strong>@chatscope/chat-ui-kit-react</strong> library for
                      the chat UI components and <strong>axios</strong> for
                      making API requests to the backend server.
                    </p>
                    <p className="mt-2">
                      Here's an explanation of the main parts of the code:
                    </p>
                    <div className="mt-10">
                      <p className="text-base font-semibold leading-7 text-indigo-600">
                        State and Context
                      </p>
                      <div className="mt-3">
                        <ul className="space-y-8 text-gray-600">
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The component uses the <strong>useState</strong>{" "}
                              hook to manage the state of{" "}
                              <strong>messageEvents</strong> (an array of chat
                              messages) and <strong>chatLoading</strong> (a
                              boolean indicating if the chat is loading).
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-10">
                      <p className="text-base font-semibold leading-7 text-indigo-600">
                        Helper Functions
                      </p>
                      <div className="mt-3">
                        <ul className="space-y-8 text-gray-600">
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              <strong>scrollDown</strong>: This function scrolls
                              the chat container to the bottom whenever new
                              messages are added.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              <strong>sanitizeContent</strong>: This function
                              removes any HTML tags from the user's input
                              message.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              <strong>sendMessage</strong>: This async function
                              sends the user's message to the backend server via
                              a POST request, updates the{" "}
                              <strong>messageEvents</strong> state with the
                              user's message, and then updates it again with the
                              response from the server. It also sets the{" "}
                              <strong>chatLoading</strong> state to{" "}
                              <strong>true</strong> while the request is being
                              made and back to <strong>false</strong> once it's
                              completed.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              <strong>handleExit</strong>: This async function
                              is called when the user clicks the "
                              <strong>Exit</strong>" button. It sends a GET
                              request to the backend server to reset the
                              conversation, updates the{" "}
                              <strong>messageEvents</strong> state with the
                              response, and sets the{" "}
                              <strong>chatLoading</strong> state accordingly.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-10">
                      <p className="text-base font-semibold leading-7 text-indigo-600">
                        Effects
                      </p>
                      <div className="mt-3">
                        <ul className="space-y-8 text-gray-600">
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The component uses the <strong>useEffect</strong>{" "}
                              hook to automatically call the{" "}
                              <strong>handleExit</strong> function after a 4ms
                              delay when the component mounts. This initializes
                              the chat with the bot's greeting message.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              Another <strong>useEffect</strong> hook is used to
                              scroll the chat container to the bottom whenever
                              the <strong>messageEvents</strong> state changes.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-10">
                      <p className="text-base font-semibold leading-7 text-indigo-600">
                        Rendering
                      </p>
                      <div className="mt-3">
                        <ul className="space-y-8 text-gray-600">
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The component renders a{" "}
                              <strong>MainContainer</strong> that contains a{" "}
                              <strong>ChatContainer</strong>.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              Inside the <strong>ChatContainer</strong>, there's
                              a <strong>MessageList</strong> that renders the
                              chat messages based on the{" "}
                              <strong>messageEvents</strong> state. Each message
                              is rendered differently based on its{" "}
                              <strong>role</strong> (either "bot", "user", or
                              "initial").
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              Below the <strong>MessageList</strong>, there's an{" "}
                              <strong>InputToolbox</strong> that contains an
                              "Exit" button, which calls the{" "}
                              <strong>handleExit</strong> function when clicked.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              Finally, there's a <strong>MessageInput</strong>{" "}
                              component that allows the user to type and send
                              messages. When a message is sent, the{" "}
                              <strong>sendMessage</strong> function is called
                              with the user's input.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-10">
                      <p className="text-base font-semibold leading-7 text-indigo-600">
                        API Integration
                      </p>
                      <div className="mt-3">
                        <ul className="space-y-8 text-gray-600">
                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The component uses <strong>axios</strong> to make
                              API requests to the backend server.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The base URL for the API requests is obtained from
                              an environment variable{" "}
                              <strong>REACT_APP_API_BASE_URL</strong>.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The <strong>sendMessage</strong> function sends a
                              POST request to the{" "}
                              <strong>/api/chat/shop-assist-chat/</strong>{" "}
                              endpoint with the user's message in the request
                              body.
                            </span>
                          </li>

                          <li className="flex gap-x-3">
                            <CheckCircleIcon
                              className="mt-1 h-5 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            <span>
                              The <strong>handleExit</strong> function sends a
                              GET request to the same endpoint to reset the
                              conversation.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <p className="mt-10">
                      Overall, this component provides an interactive chat
                      interface for users to communicate with a laptop
                      recommendation system. It handles sending user messages to
                      the backend, receiving responses, and updating the chat UI
                      accordingly. The component also provides a way to reset
                      the conversation when needed.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
                <div className="mt-12">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Area of Improvement
                  </h1>
                  <div className="mt-10 ml-4 sm:ml-11">
                    <p className="mt-2">
                      Some of the chat doesn't respond properly. Working on it
                      so that the chat give consistent result.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectCodebase;
