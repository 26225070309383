import React from "react";

import { icons, size } from "./Icons";

const SvgIcon = ({ iconName, sizeName, className="", color="#1e3050", name }) => {
  const fill = color;
  const IconComponent = icons[iconName];
  const iconSize = size[sizeName];
  return IconComponent ? (
    <IconComponent width={iconSize} height={iconSize} fill={fill} className={`${className}`} name={name} />
  ) : null;
};

export default SvgIcon;