import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Popover, Transition } from "@headlessui/react";
import axios from "axios";

import SvgIcon from "../icons/SvgIcon";
// import { useAuth } from "../authContext";
import { useCurrency } from "../context/CurrencyContext";
import SideSheetChat from "../pages/sidesheet-chat/sidesheet-chat";

const navigation = {
  pages: [
    { name: "Stores", href: "/store" },
    { name: "Dashboard", href: "/dashboard" },
    // { name: "Chat", href: "/chat" },
  ],
};

const Nav = () => {
  // const navigate = useNavigate();
  // const { isAuthenticated, signOut } = useAuth();
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState();
  const { selectedCurrency, setSelectedCurrency } = useCurrency();
  const [openSideSheet, setOpenSideSheet] = useState(false);

  const location = useLocation();

  // const handleAuthClick = () => {
  //   if (isAuthenticated) {
  //     signOut();
  //     navigate("/auth/signin");
  //   } else {
  //     navigate("/auth/signin");
  //   }
  // };

  const handleCurrencyChange = (event) => {
    const selectedCurrencyObj = currency.find(
      (c) => c.code === event.target.value
    );
    setSelectedCurrency(selectedCurrencyObj);
    localStorage.setItem(
      "selectedCurrency",
      JSON.stringify(selectedCurrencyObj)
    );
  };

  const currenciesList = useCallback(async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiBaseUrl}/api/currency/list`);
      setCurrency(response.data);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }, []);

  const handleChatClick = () => {
    setOpenSideSheet(true);
  };

  useEffect(() => {
    currenciesList();
  }, [currenciesList]);

  useEffect(() => {
    const storedCurrency = localStorage.getItem("selectedCurrency");
    if (storedCurrency) {
      setSelectedCurrency(JSON.parse(storedCurrency));
    } else {
      if (currency && currency.length > 0) {
        const defaultCurrency = currency[0];
        setSelectedCurrency(defaultCurrency);
      }
    }
  }, [setSelectedCurrency, currency]);

  return (
    <div className="bg-orange-50">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-orange-50 pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <SvgIcon iconName="duo-xmark" sizeName="xl" alt="Close" />
                  </button>
                </div>

                <div className="space-y-6 border-t border-orange-300 px-4 py-6">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <Link
                        to={page.href}
                        className={`-m-2 block p-2 font-medium text-gray-900 ${
                          location.pathname === page.href ||
                          (location.pathname === "/" && page.href === "/store")
                            ? "text-orange-600 hover:text-orange-700"
                            : ""
                        }`}
                      >
                        {page.name}
                      </Link>
                    </div>
                  ))}
                </div>

                <div className="border-t border-orange-300 px-4 py-6">
                  <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2">
                    <select
                      id="mobile-currency"
                      name="currency"
                      className="flex items-center rounded-md border-transparent bg-orange-50 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"
                      value={selectedCurrency?.code}
                      onChange={handleCurrencyChange}
                    >
                      {currency?.map((each) => (
                        <option key={each.id}>{each.code}</option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                      <SvgIcon
                        iconName="duo-angle-down"
                        sizeName="lg"
                        alt="bag-shopping"
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        >
          <div className="border-orange-300 border-b-2">
            <div className="flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center lg:hidden">
                <button
                  type="button"
                  className="-ml-2 rounded-md bg-orange-50 p-2 text-gray-400"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open menu</span>
                  <SvgIcon iconName="duo-bars" sizeName="lg" alt="Close" />
                </button>

                <Link
                  to="/"
                  className="ml-2 p-2 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Search</span>
                  <SvgIcon
                    iconName="duo-magnifying-glass"
                    sizeName="lg"
                    alt="magnifying-glass"
                  />
                </Link>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:block lg:flex-1 lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation.pages.map((page) => (
                    <Link
                      to={page.href}
                      key={page.name}
                      className={`flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 ${
                        location.pathname === page.href ||
                        (location.pathname === "/" && page.href === "/store")
                          ? "text-orange-600 hover:text-orange-700"
                          : ""
                      }`}
                    >
                      {page.name}
                    </Link>
                  ))}

                  <button
                    onClick={handleChatClick}
                    className="text-sm font-semibold leading-6 p-2 text-gray-900 hover:text-gray-500 lg:ml-4"
                  >
                    Chat
                  </button>
                </div>
              </Popover.Group>

              {/* Logo */}
              <div className="flex items-center">
                <Link to="/store">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </Link>
                <div className="text-xl font-semibold leading-6 text-gray-900 hover:text-gray-500 ml-2">
                  Retail Buddy
                </div>
              </div>

              <div className="flex flex-1 items-center justify-end">
                <div className="hidden group relative -ml-2 rounded-md border-transparent lg:flex lg:items-center">
                  <select
                    id="mobile-currency"
                    name="currency"
                    className="flex items-center rounded-md border-transparent bg-orange-50 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"
                    value={selectedCurrency?.code}
                    onChange={handleCurrencyChange}
                  >
                    {currency?.map((each) => (
                      <option key={each.id}>{each.code}</option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <SvgIcon
                      iconName="duo-angle-down"
                      sizeName="lg"
                      alt="bag-shopping"
                    />
                  </div>
                </div>

                {/* Search */}
                <Link
                  to="/"
                  className="ml-6 hidden p-2 text-gray-400 hover:text-gray-500 lg:block"
                >
                  <span className="sr-only">Search</span>
                  <SvgIcon
                    iconName="duo-magnifying-glass"
                    sizeName="lg"
                    alt="magnifying-glass"
                  />
                </Link>

                {/* Account */}
                {/* <button
                  onClick={handleAuthClick}
                  className="text-sm font-semibold leading-6 p-2 text-gray-900 hover:text-gray-500 lg:ml-4"
                >
                  {isAuthenticated ? "Sign Out" : "Sign In"}
                </button> */}
              </div>
            </div>
          </div>
        </nav>
      </header>

      <Transition.Root show={openSideSheet} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenSideSheet}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpenSideSheet(false)}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <SvgIcon
                            iconName="duo-xmark"
                            sizeName="xl"
                            color="#ffffff"
                            alt="Close"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-orange-50 py-4 shadow-xl">
                      <div className="px-4 sm:px-6 border-b pb-2 border-orange-300">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Chat
                        </Dialog.Title>
                      </div>
                      <div className="relative flex-1">
                        <SideSheetChat />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Nav;
