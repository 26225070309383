const IconContainer = ({
  children,
  onClick,
  name,
  className,
  bgColor = "",
  title,
}) => {
  return (
    <div title={title} className="flex justify-center items-center ">
      <div
        className={`p-2 flex items-center justify-center cursor-pointer rounded-full hover:bg-gray-200 ${className} ${bgColor}`}
        onClick={onClick}
        name={name}
      >
        {children}
      </div>
    </div>
  );
};

export default IconContainer;
