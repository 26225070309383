/*
  A wrapper around React Router that adds a useRouter() hook so that any component
  can easily access params, location, history, and trigger navigation.
  Import from this file instead of react-router-dom directly.
*/

import { useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

// Custom useRouter hook for getting route data and methods inside any component.
// NOTE: This hook includes all React Router hooks, which can result in extra re-renders
// in some cases. When needed, you can optimize performance by importing the specific hook
// you need (such as useParams or useLocation) instead of this custom useRouter hook.
export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      params,
      location,
      // For convenience add push(), replace(), pathname at top level
      push: navigate,
      replace: navigate,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
    };
  }, [params, location, navigate]);
}
