import React from "react";

const TableSkeleton = () => {
  return (
    <div className="container mx-auto max-w-6xl rounded-md mt-10 py-12">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="space-y-1">
            <div className="grid grid-cols-3 gap-1">
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
            </div>
            <div className="grid grid-cols-3 gap-1">
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
            </div>
            <div className="grid grid-cols-3 gap-1">
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
              <div className="h-16 bg-orange-300 rounded col-span-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSkeleton;
