import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const ForgotPasswordPage = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className="my-4">
      <div className="text-black font-semibold">New Password</div>
      <div className="px-4 py-3 m-3 rounded-lg backdrop-blur-md">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-2">
            <input
              className={
                "px-3 w-full leading-8 rounded text-base text-gray-800 bg-white border-gray-300 border outline-none focus:border-blue-500 focus:ring-1"
              }
              name="email"
              type="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <div className="text-red-600 text-xs font-semibold text-left px-3 h-4">
              {formik.touched.email && formik.errors.email && (
                <>{formik.errors.email}</>
              )}
            </div>
          </div>

          <button
            className="py-[6px] px-4 w-full text-base text-white font-semibold bg-[#4d4d4d] flex justify-center rounded border-0 hover:bg-[#8b8b8b] focus:outline-none"
            type="submit"
            name="submit_button"
          >
            Reset Password
          </button>
        </form>

        <div className="px-3 mt-3 text-sm">
          <span>Remember it after all?</span>
          <Link className="ml-4 text-[#004cff]" to="/auth/signin">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
