import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';


const CustomDatePicker = ({onDateSelect}) => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        const formattedDate = date.toISOString();
        onDateSelect(formattedDate);
    }

    return(
        <div>
            <DatePicker 
            selected={selectedDate}
            onChange={(date) => handleDateSelect(date)}
            dateFormat="MMMM d, yyyy"
            style={{fontSize: '0.75rem', lineHeight: '1.25rem'}}
            className="date-picker px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
        </div>
    );
}

export default CustomDatePicker;