import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useCurrency } from "../../context/CurrencyContext";
import {
  convertINRtoUSD,
  formatPrice,
} from "../../re-usable-components/currencyUtils/CurrencyUtils";
import Meta from "../../re-usable-components/meta/meta";
import StoreSkeleton from "../../skeleton/storeskeleton";

// const products = [
//   {
//     id: 1,
//     name: "Zip Tote Basket",
//     color: "White and black",
//     href: "#",
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg",
//     imageAlt:
//       "Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.",
//     price: "$140",
//   },
//   // More products...
// ];

const Store = () => {
  const [products, setProducts] = useState();
  const { selectedCurrency } = useCurrency();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiEndpoint = `${apiBaseUrl}/api/laptop/`;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(apiEndpoint);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data) {
          setProducts(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Meta title="Store | Your One-Stop Shop for Laptops - ShopAssist" />
      <section className="min-h-screen">
        <div className="bg-orange-50">
          <div className="mx-auto max-w-2xl p-4 lg:max-w-7xl bg-orange-100 rounded-md">
            <h2 className="text-xl font-bold text-gray-900">Laptops</h2>

            {!isLoading && products?.length > 0 && (
              <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {products?.map((product) => (
                  <div key={product.id}>
                    <Link to={`/store-details/${product.id}`}>
                      <div className="relative">
                        <div className="relative h-72 w-full overflow-hidden rounded-lg">
                          <img
                            src={product.thumbnail}
                            alt={product.brand}
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="relative mt-4">
                          <h3 className="text-sm font-medium text-gray-900">
                            {product.brand} {product.model_name}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Core {product.core}
                          </p>
                        </div>
                        <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                          <div
                            aria-hidden="true"
                            className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                          />
                          <p className="relative text-lg font-semibold text-white">
                            {selectedCurrency?.symbol}
                            {selectedCurrency?.code === "INR"
                              ? formatPrice(product.price)
                              : convertINRtoUSD(product.price)}
                          </p>
                        </div>
                      </div>
                      <div className="mt-6">
                        <a
                          href={product.href}
                          className="relative flex items-center justify-center rounded-md border border-transparent bg-orange-200 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-orange-300"
                        >
                          Add to bag
                          <span className="sr-only">, {product.brand}</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            )}
            {isLoading && !products?.length && <StoreSkeleton />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Store;
