import React, { useState, Fragment, useEffect, useCallback } from "react";
import axios from "axios";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { useCurrency } from "../../context/CurrencyContext";
import TableSkeleton from "../../skeleton/tableskeleton";
import Meta from "../../re-usable-components/meta/meta";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashBoard = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [data, setData] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const { selectedCurrency } = useCurrency();
  const years = Array.from(new Array(11), (val, index) => year - 5 + index);
  const months = Array.from(new Array(12), (val, index) => index + 1);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const fetchAndSetData = useCallback(async () => {
    setLoading(true);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${apiBaseUrl}/api/chat/tokens-details-by-month-year/`,
        {
          year: year,
          month: month,
          currency_id: selectedCurrency?.id,
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [year, month, selectedCurrency]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchAndSetData();
  };

  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);

  useEffect(() => {
    // Whenever data changes, recalculate the total cost.
    const total = data.reduce(
      (acc, item) => acc + item.total_cost_in_target_currency,
      0
    );
    setTotalCost(total);
  }, [data]);

  return (
    <>
      <Meta title="Dashboard | Monitor Your AI Costs - ShopAssist" />
      <section className="min-h-screen bg-orange-50">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-orange-100 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-center">
              <div className="flex gap-4">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-orange-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-orange-300 hover:bg-orange-200">
                    Month: {monthNames[month - 1]}
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition as={Fragment} {...transitionProps}>
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-orange-100 ring-1 ring-orange-300 ring-opacity-5 focus:outline-none z-10">
                      {months.map((m) => (
                        <Menu.Item key={m}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active
                                  ? "bg-orange-200 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={() => setMonth(m)}
                            >
                              {monthNames[m - 1]}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>

                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-orange-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-orange-300 hover:bg-orange-200 mb-1">
                    Year: {year}
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition as={Fragment} {...transitionProps}>
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-orange-100 ring-1 ring-orange-300 ring-opacity-5 focus:outline-none z-10">
                      {years.map((y) => (
                        <Menu.Item key={y}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active
                                  ? "bg-orange-200 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={() => setYear(y)}
                            >
                              {y}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>

                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-orange-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-orange-300 hover:bg-orange-200">
                    Total Cost: {selectedCurrency?.symbol}
                    {totalCost}
                  </Menu.Button>
                </Menu>
              </div>
            </div>
          </form>
          {!loading && data.length > 0 && (
            <div className="mt-3 flow-root overflow-hidden">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <table className="w-full text-left">
                  <thead className="bg-orange-100">
                    <tr>
                      <th
                        scope="col"
                        className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Date
                        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-orange-300" />
                        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-orange-300" />
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Model Name
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                      >
                        Cost
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((each, index) => (
                      <tr key={index}>
                        <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                          {each.date}
                          <div className="absolute bottom-0 right-full h-px w-screen bg-orange-200" />
                          <div className="absolute bottom-0 left-0 h-px w-screen bg-orange-200" />
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          {each.model_name}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                          {selectedCurrency?.symbol}
                          {each.total_cost_in_target_currency}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {!loading && !data.length && (
            <p className="text-2xl font-semibold p-2 pb-7 text-gray-900 hover:text-gray-500 text-center">
              No data found in particular month and year.
            </p>
          )}
          {loading && !data.length && <TableSkeleton />}
        </div>
      </section>
    </>
  );
};

const transitionProps = {
  enter: "transition ease-out duration-100",
  enterFrom: "transform opacity-0 scale-95",
  enterTo: "transform opacity-100 scale-100",
  leave: "transition ease-in duration-75",
  leaveFrom: "transform opacity-100 scale-100",
  leaveTo: "transform opacity-0 scale-95",
};

export default DashBoard;
