import * as fx from "money";

fx.base = "INR";
fx.rates = {
  USD: 0.012, // Example exchange rate, replace with actual rate
};

export const formatPrice = (price) => {
  const formattedPrice = price?.toLocaleString("en-IN", {
    maximumFractionDigits: 0,
    currency: "INR",
  });
  return formattedPrice;
};

export const convertINRtoUSD = (amount) => {
  const usdAmount = fx.convert(amount, { from: "INR", to: "USD" });
  return usdAmount;
};
