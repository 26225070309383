import { useEffect, useState } from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import {
  CurrencyDollarIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";

import { useCurrency } from "../../context/CurrencyContext";
import {
  convertINRtoUSD,
  formatPrice,
} from "../../re-usable-components/currencyUtils/CurrencyUtils";

const product = {
  name: "Basic Tee",
  price: "$35",
  rating: 3.9,
  reviewCount: 512,
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Women", href: "#" },
    { id: 2, name: "Clothing", href: "#" },
  ],
  images: [
    {
      id: 1,
      imageSrc:
        "https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg",
      imageAlt: "Back of women's Basic Tee in black.",
      primary: true,
    },
    {
      id: 2,
      imageSrc:
        "https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-01.jpg",
      imageAlt: "Side profile of women's Basic Tee in black.",
      primary: false,
    },
    {
      id: 3,
      imageSrc:
        "https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-02.jpg",
      imageAlt: "Front of women's Basic Tee in black.",
      primary: false,
    },
  ],
  colors: [
    { name: "Black", bgColor: "bg-gray-900", selectedColor: "ring-gray-900" },
    {
      name: "Heather Grey",
      bgColor: "bg-gray-400",
      selectedColor: "ring-gray-400",
    },
  ],
  sizes: [
    { name: "XXS", inStock: true },
    { name: "XS", inStock: true },
    { name: "S", inStock: true },
    { name: "M", inStock: true },
    { name: "L", inStock: true },
    { name: "XL", inStock: false },
  ],
  description: `
    <p>The Basic tee is an honest new take on a classic. The tee uses super soft, pre-shrunk cotton for true comfort and a dependable fit. They are hand cut and sewn locally, with a special dye technique that gives each tee it's own look.</p>
    <p>Looking to stock your closet? The Basic tee also comes in a 3-pack or 5-pack at a bundle discount.</p>
  `,
  details: [
    "Only the best materials",
    "Ethically and locally made",
    "Pre-washed and pre-shrunk",
    "Machine wash cold with similar colors",
  ],
};
const policies = [
  {
    name: "International delivery",
    icon: GlobeAmericasIcon,
    description: "Get your order in 2 years",
  },
  {
    name: "Loyalty rewards",
    icon: CurrencyDollarIcon,
    description: "Don't look at other tees",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Details = () => {
  const { id } = useParams();
  const { selectedCurrency } = useCurrency();
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [productDetails, setProductDetails] = useState();

  useEffect(() => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiEndpoint = `${apiBaseUrl}/api/laptop/id/?id=${id}`;

    const fetchData = async () => {
      try {
        const response = await fetch(apiEndpoint);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data && data.length > 0) {
          setProductDetails(data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="min-h-screen">
      <div className="bg-orange-50">
        <div className="mx-auto max-w-2xl p-4 lg:max-w-7xl bg-orange-100 rounded-md">
          <nav
            aria-label="Breadcrumb"
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            <ol className="flex items-center space-x-4">
              <li>
                <div className="flex items-center">
                  <span className="mr-4 text-sm font-medium text-gray-900">
                    Laptop
                  </span>
                  <svg
                    viewBox="0 0 6 20"
                    aria-hidden="true"
                    className="h-5 w-auto text-gray-300"
                  >
                    <path
                      d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </li>
              <li className="text-sm">
                <span
                  aria-current="page"
                  className="font-medium text-gray-500 hover:text-gray-600"
                >
                  {productDetails?.brand} {productDetails?.model_name}
                </span>
              </li>
            </ol>
          </nav>
          <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
              <div className="lg:col-span-5 lg:col-start-8">
                <div className="flex justify-between">
                  <h1 className="text-xl font-medium text-gray-900">
                    {productDetails?.brand} {productDetails?.model_name}
                  </h1>
                  <p className="text-xl font-medium text-gray-900">
                    {selectedCurrency?.symbol}
                    {selectedCurrency?.code === "INR"
                      ? formatPrice(productDetails?.price)
                      : convertINRtoUSD(productDetails?.price)}
                  </p>
                </div>
                {/* Reviews */}
                <div className="mt-4">
                  <h2 className="sr-only">Reviews</h2>
                  <div className="flex items-center">
                    <p className="text-sm text-gray-700">
                      {product.rating}
                      <span className="sr-only"> out of 5 stars</span>
                    </p>
                    <div className="ml-1 flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          className={classNames(
                            product.rating > rating
                              ? "text-yellow-400"
                              : "text-gray-200",
                            "h-5 w-5 flex-shrink-0"
                          )}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                    <div
                      aria-hidden="true"
                      className="ml-4 text-sm text-gray-300"
                    >
                      ·
                    </div>
                    <div className="ml-4 flex">
                      <Link
                        to=""
                        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        See all {product.reviewCount} reviews
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Image gallery */}
              <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                <h2 className="sr-only">Images</h2>

                <div>
                  <img
                    src={productDetails?.thumbnail}
                    alt={productDetails?.brand}
                    className={"w-full h-full object-cover rounded-lg"}
                  />
                </div>
              </div>

              <div className="mt-8 lg:col-span-5">
                <form>
                  {/* Color picker */}
                  <div>
                    <h2 className="text-sm font-medium text-gray-900">Color</h2>

                    <RadioGroup
                      value={selectedColor}
                      onChange={setSelectedColor}
                      className="mt-2"
                    >
                      <RadioGroup.Label className="sr-only">
                        Choose a color
                      </RadioGroup.Label>
                      <div className="flex items-center space-x-3">
                        {product.colors.map((color) => (
                          <RadioGroup.Option
                            key={color.name}
                            value={color}
                            className={({ active, checked }) =>
                              classNames(
                                color.selectedColor,
                                active && checked ? "ring ring-offset-1" : "",
                                !active && checked ? "ring-2" : "",
                                "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                              )
                            }
                          >
                            <RadioGroup.Label as="span" className="sr-only">
                              {color.name}
                            </RadioGroup.Label>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                color.bgColor,
                                "h-8 w-8 rounded-full border border-black border-opacity-10"
                              )}
                            />
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <button
                    type="submit"
                    className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-orange-200 px-8 py-3 text-base font-medium text-gray-900 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  >
                    Add to cart
                  </button>
                </form>

                {/* Product details */}
                <div className="mt-10">
                  <h2 className="text-sm font-medium text-gray-900">
                    Description
                  </h2>

                  <div className="prose prose-sm mt-4 text-gray-500">
                    {productDetails?.description}
                  </div>
                </div>

                <div className="mt-8 border-t border-orange-300 pt-8">
                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">Core</h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.core}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">OS</h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.os}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Display Size
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.display_size}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Display Type
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.display_type}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Cpu Manufacturer
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.cpu_manufacturer}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Graphics Processor
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.graphics_processor}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Ram Size
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.ram_size}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Storage Type
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.storage_type}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Clock Speed
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.clock_speed}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Average Battery Life
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.average_battery_life}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Laptop Weight
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.laptop_weight}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Screen Resolution
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.screen_resolution}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Special Features
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.special_features}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <h2 className="text-sm font-medium text-gray-900">
                      Warranty
                    </h2>

                    <div className="prose prose-sm text-gray-500">
                      : {productDetails?.warranty}
                    </div>
                  </div>
                </div>

                {/* Policies */}
                <section aria-labelledby="policies-heading" className="mt-10">
                  <h2 id="policies-heading" className="sr-only">
                    Our Policies
                  </h2>

                  <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                    {policies.map((policy) => (
                      <div
                        key={policy.name}
                        className="rounded-lg border border-orange-300 bg-orange-100 p-6 text-center"
                      >
                        <dt>
                          <policy.icon
                            className="mx-auto h-6 w-6 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="mt-4 text-sm font-medium text-gray-900">
                            {policy.name}
                          </span>
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500">
                          {policy.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
