import React, { useEffect, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  InputToolbox,
  Button,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import axios from "axios";

import messageBot from "../../icons/svg/duotone/message-bot.svg";
import user from "../../icons/svg/duotone/circle-user.svg";
import "./chat.css";

const Chat = () => {
  const [messageEvents, setMessageEvents] = useState([]);

  const scrollDown = () => {
    let scrollContainer = document.querySelector(
      ".message-list .scrollbar-container"
    );
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  const sendMessage = async (content) => {
    const user_message = {
      user_id: "",
      name: "",
      content: content,
      role: "user",
      fileId: "",
      id: "",
      created_at: Date.now(),
    };
    setMessageEvents((prevMessages) => [...prevMessages, user_message]);

    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.post(
        `${apiBaseUrl}/api/chat/chat-completion/`,
        {
          user_input: content,
        }
      );

      // Handle the response from the API
      const ai_message = {
        ...response.data,
        content: response.data[1][1][0][3][1][0][1],
        role: "assistant",
        created_at: Date.now(),
      };
      setMessageEvents((prevMessages) => [...prevMessages, ai_message]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const laptopList = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiBaseUrl}/api/laptop/id/?id=4,8`);

      const laptops = response.data;

      const laptopContents = laptops.map((laptop) => {
        return `
          <div style='display:flex;align-items: center;justify-content: center;flex-direction: column;box-shadow: 0px 1px 3px #c7c7c7;background: white;border-radius: 4px;padding: 12px;'>
            <span style=''><img src="${laptop.thumbnail}" alt="Base64 Image" /></span>
            <span style='font-size: 24px;font-weight: 600;'>${laptop.brand} ${laptop.model_name}</span>
            <span style='font-size: 14px;font-weight: 300;'>${laptop.price} INR</span>
            <button style='font-size: 14px;font-weight: 600;color: #2a12e1;margin-top: 12px;'>BUY</button>
          </div>
        `;
      });

      const newMessage = {
        role: "initial",
        content: laptopContents.join(""),
      };

      setMessageEvents((prevMessages) => [...prevMessages, newMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleExit = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(
        `${apiBaseUrl}/api/chat/shop-assist-chat/`
      );

      console.log(11, response.data);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      laptopList();
    }, 4);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    scrollDown();
  }, [messageEvents]);

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-[630px] sm:h-[880px]">
      <div className="h-full" style={{ position: "relative" }}>
        <MainContainer>
          <ChatContainer>
            <MessageList className="message-list" scrollBehavior="smooth">
              {messageEvents.map((messageEvent, index) => {
                switch (messageEvent.role) {
                  case "assistant":
                    return (
                      <Message
                        key={index}
                        model={{
                          message: messageEvent.content,
                          sentTime: "just now",
                          sender: messageEvent.name,
                          position: "single",
                          direction: "incoming",
                        }}
                        avatarPosition="tl"
                      >
                        <Avatar src={messageBot} name={messageEvent.name} />
                      </Message>
                    );
                  case "user":
                    return (
                      <Message
                        key={index}
                        model={{
                          message: messageEvent.content,
                          sentTime: "just now",
                          sender: messageEvent.name,
                          position: "single",
                          direction: "outgoing",
                        }}
                        avatarPosition="tr"
                      >
                        <Avatar src={user} name={messageEvent.name} />
                      </Message>
                    );
                  case "initial":
                    return (
                      <Message
                        key={index}
                        model={{
                          type: "html",
                          direction: "incoming",
                        }}
                        avatarPosition="tl"
                      >
                        <Avatar src={messageBot} name={messageEvent.name} />
                        <Message.HtmlContent html={messageEvent.content} />
                      </Message>
                    );
                  default:
                    return null;
                }
              })}
            </MessageList>
            <InputToolbox>
              <Button
                border
                onClick={handleExit}
                style={{
                  padding: "0px 8px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Exit
              </Button>
            </InputToolbox>
            <MessageInput
              placeholder={"Type a message..."}
              onSend={sendMessage}
              attachButton={false}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default Chat;
