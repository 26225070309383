import React, { useCallback, useEffect, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  InputToolbox,
  Button,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import axios from "axios";

import "./sidesheet-chat.css";
import messageBot from "../../icons/svg/duotone/message-bot.svg";
import user from "../../icons/svg/duotone/circle-user.svg";

const SideSheetChat = () => {
  const [messageEvents, setMessageEvents] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);

  const scrollDown = () => {
    let scrollContainer = document.querySelector(
      ".side-sheet-message-list .scrollbar-container"
    );
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

 
  const sanitizeContent = (content) => {
    return content.replace(/<[^>]*>?/gm, '');
  };

  const sendMessage = async (content) => {
    setChatLoading(true);
    const cleanContent = sanitizeContent(content);


    setMessageEvents((prevMessages) => [
      ...prevMessages,
      { role: "user", content: cleanContent },
    ]);

    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.post(
        `${apiBaseUrl}/api/chat/shop-assist-chat/`,
        {
          user_input: cleanContent,
        }
      );

      setMessageEvents((prevMessages) => {
        const updatedMessages = [...prevMessages];

        const lastTwoMessages = response.data.slice(-1);

        lastTwoMessages.forEach((data) => {
          if (data.bot) {
            updatedMessages.push({
              role: "bot",
              content: data.bot,
            });
          } else if (data.user) {
            updatedMessages.push({
              role: "user",
              content: data.user,
            });
          } else if (data.initial) {
            updatedMessages.push({
              role: "initial",
              content: data.initial,
            });
          }
        });

        return updatedMessages;
      });
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setChatLoading(false);
    }
  };

  const handleExit = useCallback(async () => {
    setChatLoading(true);
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(
        `${apiBaseUrl}/api/chat/shop-assist-chat/`
      );
  
      const updatedMessages = [];
      let data = response.data;
      if (data.bot) {
        updatedMessages.push({
          role: "bot",
          content: data.bot,
        });
      } else if (data.user) {
        updatedMessages.push({
          role: "user",
          content: data.user,
        });
      } else if (data.initial) {
        updatedMessages.push({
          role: "initial",
          content: data.initial,
        });
      }
  
      setMessageEvents(updatedMessages);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setChatLoading(false);
    }
  }, []); 

  useEffect(() => {
    const timer = setTimeout(() => {
      handleExit();
    }, 4);

    return () => clearTimeout(timer);
  }, [handleExit]);

  useEffect(() => {
    scrollDown();
  }, [messageEvents]);

  return (
    <div className="mx-auto max-w-7xl h-[630px] sm:h-[880px]">
      <div className="h-full" style={{ position: "relative" }}>
        <MainContainer className="side-sheet-chat">
          <ChatContainer>
            <MessageList
              className="side-sheet-message-list"
              scrollBehavior="smooth"
              typingIndicator={
                chatLoading && <TypingIndicator content="Loading" />
              }
            >
              {messageEvents.map((messageEvent, index) => {
                switch (messageEvent.role) {
                  case "bot":
                    return (
                      <Message
                        key={index}
                        model={{
                          message: messageEvent.content,
                          sentTime: "just now",
                          sender: messageEvent.name,
                          position: "single",
                          direction: "incoming",
                        }}
                        avatarPosition="tl"
                      >
                        <Avatar src={messageBot} name={messageEvent.name} />
                      </Message>
                    );
                  case "user":
                    return (
                      <Message
                        key={index}
                        model={{
                          message: messageEvent.content,
                          sentTime: "just now",
                          sender: messageEvent.name,
                          position: "single",
                          direction: "outgoing",
                        }}
                        avatarPosition="tr"
                      >
                        <Avatar src={user} name={messageEvent.name} />
                      </Message>
                    );
                  case "initial":
                    return (
                      <Message
                        key={index}
                        model={{
                          type: "html",
                          direction: "incoming",
                        }}
                        avatarPosition="tl"
                      >
                        <Avatar src={messageBot} name={messageEvent.name} />
                        <Message.HtmlContent html={messageEvent.content} />
                      </Message>
                    );
                  default:
                    return null;
                }
              })}
            </MessageList>
            <InputToolbox>
              <Button
                border
                onClick={handleExit}
                style={{
                  padding: "0px 8px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Exit
              </Button>
            </InputToolbox>
            <MessageInput
              placeholder={"Type a message..."}
              onSend={sendMessage}
              attachButton={false}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default SideSheetChat;
