import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Nav from "./nav/Nav";
import ForgotPasswordPage from "./pages/auth/forgot-password/forgot-password-page";
import SignInPage from "./pages/auth/sign-in/sign-in-page";
import SignUpPage from "./pages/auth/sign-up/sign-up-page";
import HomePage from "./pages/home-page/home-page";
import UserForm from "./pages/user/user";
import { AuthProvider } from "./authContext";
import ProductPage from "./pages/product-page/product-page";
import Chat from "./pages/chat/chat";
import DashBoard from "./pages/dashboard/dashboard";
import Store from "./pages/store/store";
import Details from "./pages/store/details";
import Footer from "./pages/footer/footer";
import ProjectReport from "./pages/project-report/project-report";
import ProjectCodebase from "./pages/project-codebase/project-codebase";

// const products = [
//   {
//     id: 1,
//     name: "Organize Basic Set (Walnut)",
//     price: "$149",
//     rating: 5,
//     reviewCount: 38,
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg",
//     imageAlt: "TODO",
//     images: [
//       {
//         id: 1,
//         name: "Angled view",
//         src: "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg",
//         alt: "TODO",
//       },
//       // More images...
//     ],
//     description: `
//     <p>The Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.</p>
//   `,
//     colors: [
//       {
//         name: "Washed Black",
//         bgColor: "bg-gray-700",
//         selectedColor: "ring-gray-700",
//       },
//       { name: "White", bgColor: "bg-white", selectedColor: "ring-gray-400" },
//       {
//         name: "Washed Gray",
//         bgColor: "bg-gray-500",
//         selectedColor: "ring-gray-500",
//       },
//     ],
//     details: [
//       {
//         name: "Features",
//         items: [
//           "Multiple strap configurations",
//           "Spacious interior with top zip",
//           "Leather handle and tabs",
//           "Interior dividers",
//           "Stainless strap loops",
//           "Double stitched construction",
//           "Water-resistant",
//         ],
//       },
//       // More sections...
//     ],
//     relatedProducts: [
//       {
//         id: 1,
//         name: "Zip Tote Basket",
//         color: "White and black",
//         href: "#",
//         imageSrc:
//           "https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg",
//         imageAlt:
//           "Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.",
//         price: "$140",
//       },
//       // More products...
//     ],
//   },
//   {
//     id: 2,
//     name: "Organize Pen Holder",
//     price: "$15",
//     rating: 5,
//     reviewCount: 18,
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-02.jpg",
//     imageAlt: "TODO",
//     images: [
//       {
//         id: 1,
//         name: "Angled view",
//         src: "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-02.jpg",
//         alt: "TODO",
//       },
//       // More images...
//     ],
//     description: `
//     <p>Organize Pen Holder is the Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.</p>
//   `,
//     colors: [
//       {
//         name: "Washed Black",
//         bgColor: "bg-gray-700",
//         selectedColor: "ring-gray-700",
//       },
//       { name: "White", bgColor: "bg-white", selectedColor: "ring-gray-400" },
//       {
//         name: "Washed Gray",
//         bgColor: "bg-gray-500",
//         selectedColor: "ring-gray-500",
//       },
//     ],
//     details: [
//       {
//         name: "Features",
//         items: [
//           "Multiple strap configurations",
//           "Spacious interior with top zip",
//           "Leather handle and tabs",
//           "Interior dividers",
//           "Stainless strap loops",
//           "Double stitched construction",
//           "Water-resistant",
//         ],
//       },
//       // More sections...
//     ],
//     relatedProducts: [
//       {
//         id: 1,
//         name: "Zip Tote Basket",
//         color: "White and black",
//         href: "#",
//         imageSrc:
//           "https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg",
//         imageAlt:
//           "Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.",
//         price: "$140",
//       },
//       // More products...
//     ],
//   },
//   {
//     id: 3,
//     name: "Organize Sticky Note Holder",
//     price: "$15",
//     rating: 5,
//     reviewCount: 14,
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-03.jpg",
//     imageAlt: "TODO",
//     images: [
//       {
//         id: 1,
//         name: "Angled view",
//         src: "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-03.jpg",
//         alt: "TODO",
//       },
//       // More images...
//     ],
//     description: `
//     <p>Organize Sticky Note Holder is the Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.</p>
//   `,
//     colors: [
//       {
//         name: "Washed Black",
//         bgColor: "bg-gray-700",
//         selectedColor: "ring-gray-700",
//       },
//       { name: "White", bgColor: "bg-white", selectedColor: "ring-gray-400" },
//       {
//         name: "Washed Gray",
//         bgColor: "bg-gray-500",
//         selectedColor: "ring-gray-500",
//       },
//     ],
//     details: [
//       {
//         name: "Features",
//         items: [
//           "Multiple strap configurations",
//           "Spacious interior with top zip",
//           "Leather handle and tabs",
//           "Interior dividers",
//           "Stainless strap loops",
//           "Double stitched construction",
//           "Water-resistant",
//         ],
//       },
//       // More sections...
//     ],
//     relatedProducts: [
//       {
//         id: 1,
//         name: "Zip Tote Basket",
//         color: "White and black",
//         href: "#",
//         imageSrc:
//           "https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg",
//         imageAlt:
//           "Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.",
//         price: "$140",
//       },
//       // More products...
//     ],
//   },
//   {
//     id: 4,
//     name: "Organize Phone Holder",
//     price: "$15",
//     rating: 4,
//     reviewCount: 21,
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg",
//     imageAlt: "TODO",
//     images: [
//       {
//         id: 1,
//         name: "Angled view",
//         src: "https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg",
//         alt: "TODO",
//       },
//       // More images...
//     ],
//     description: `
//     <p>Organize Phone Holder is the Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.</p>
//   `,
//     colors: [
//       {
//         name: "Washed Black",
//         bgColor: "bg-gray-700",
//         selectedColor: "ring-gray-700",
//       },
//       { name: "White", bgColor: "bg-white", selectedColor: "ring-gray-400" },
//       {
//         name: "Washed Gray",
//         bgColor: "bg-gray-500",
//         selectedColor: "ring-gray-500",
//       },
//     ],
//     details: [
//       {
//         name: "Features",
//         items: [
//           "Multiple strap configurations",
//           "Spacious interior with top zip",
//           "Leather handle and tabs",
//           "Interior dividers",
//           "Stainless strap loops",
//           "Double stitched construction",
//           "Water-resistant",
//         ],
//       },
//       // More sections...
//     ],
//     relatedProducts: [
//       {
//         id: 1,
//         name: "Zip Tote Basket",
//         color: "White and black",
//         href: "#",
//         imageSrc:
//           "https://tailwindui.com/img/ecommerce-images/product-page-03-related-product-01.jpg",
//         imageAlt:
//           "Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.",
//         price: "$140",
//       },
//       // More products...
//     ],
//   },
//   // More products...
// ];

function App() {
  return (
    <AuthProvider>
      <div className="App bg-orange-50">
        <BrowserRouter>
          <Nav />
          <Routes>
            <Route path="/" element={<Store />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/auth/signin" element={<SignInPage />} />
            <Route path="/auth/signup" element={<SignUpPage />} />
            <Route path="/auth/forgotpass" element={<ForgotPasswordPage />} />
            <Route path="/user" element={<UserForm />} />
            <Route path="/product-page/:id" element={<ProductPage />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/store" element={<Store />} />
            <Route path="/store-details/:id" element={<Details />} />
            <Route path="/project-report" element={<ProjectReport />} />
            <Route
              path="/project-codebase-document"
              element={<ProjectCodebase />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
